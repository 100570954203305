import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import router from "./router";
import store from "./store";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/LinksCss.css";
import "./assets/css/style.scss";
import "./assets/css/style-ar.scss";

import "./assets/js/main.js";

import axios from "axios";
// import VueAxios from "vue-axios";
import Pagination from "laravel-vue-pagination";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Vue.use(VueAxios, axios);
Vue.component("pagination", Pagination);

Vue.config.productionTip = false;
Vue.use(VueI18n);

// import paralex
import VueKinesis from "vue-kinesis";
Vue.use(VueKinesis);

// import vue-notifiction
import Notifications from "vue-notification";
Vue.use(Notifications);

// import  Carousel3d
import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);

// import VueAwesomeSwiper
import VueAwesomeSwiper from "vue-awesome-swiper";
// import style
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

// -------------------------
import "vue-event-calendar/dist/style.css";
import vueEventCalendar from "vue-event-calendar";
Vue.use(vueEventCalendar, { locale: "en" });
// -------------------------

// ---------------------------vue meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);
// ---------------------------vue meta

// ---------------------------vue Online Plugin
import VueOnlinePlugin from "vue-navigator-online";
Vue.use(VueOnlinePlugin);
// ---------------------------vue Online Plugin

// require vue-cookies
Vue.use(require('vue-cookies'));
Vue.$cookies.config('30d');

if (Vue.$cookies.get('lang') == null)
    Vue.$cookies.set('lang', 'en');
// require vue-cookies

import Locale from "@/lang/lang.js";
const i18n = new VueI18n({
    locale: Vue.$cookies.get('lang'),
    messages: Locale
});



// Mixins
Vue.mixin({
    data: function() {
        return {
            loading(time) {

                setTimeout(function() {
                    var loading_c = document.getElementById('loading_c')
                    var not_found = document.getElementById('not_found')
                    if (loading_c)
                        document.getElementById('loading_c').style.display = "none"; // or fade, css display however you'd like.
                    if (not_found)
                        document.getElementById('not_found').style.display = "block"; // or fade, css display however you'd like.
                }, time);
            },
            getPragraphLimit(pragraph, x) {
                return (pragraph && pragraph.length ? pragraph.length : 0) > x ?
                    pragraph.substring(0, x) + "...." :
                    pragraph;
            },
            swiperOption1: {
                pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar"
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },
            swiperOption2: {
                spaceBetween: 30,
                // loop: true,
                // loopFillGroupWithBlank: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    }
                }
            },
            swiperOption3: {
                effect: "coverflow",
                grabCursor: true,
                centeredSlides: false,
                slidesPerView: "auto",
                coverflowEffect: {
                    rotate: 60,
                    stretch: 0,
                    depth: 50,
                    modifier: 1,
                    slideShadows: true
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 2
                    },
                    640: {
                        slidesPerView: 1
                    },
                    480: {
                        slidesPerView: 1
                    }
                }
            }
        };
    },
    pdf: "",
    siteURL: ""
});

// const devInstance = createInstance("http://dashboard.etf.org.eg/api/");
const devInstance = createInstance("http://etf-admin.ispdemos.com/api/");
// const devInstance = createInstance("http://localhost:8000/api/");
function createInstance(baseURL) {
    return axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json"
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
                // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept,Authorization",
                // "X-Localization": "en"
                // 'Authorization': `Bearer ${localStorage.token}`
        }
    });
}
Vue.prototype.$http = devInstance;
Vue.use(require("vue-moment"));

new Vue({
    // metaInfo() {
    //   return {
    //     title: "ETF - Ministry of Tourism Egypt",
    //     meta: [
    //       {
    //         name: "description",
    //         content: "A website for the Ministry of Tourism in Egypt."
    //       },
    //       {
    //         property: "og:title",
    //         content: "Epiloge - Build your network in your field of interest"
    //       },
    //       { property: "og:site_name", content: "Epiloge" },
    //       { property: "og:type", content: "website" },
    //       { name: "robots", content: "index,follow" }
    //     ]
    //   };
    // },
    router,
    store,
    i18n,
    render: h => h(App)
        // render: function(h) {
        //   return h(App);
        // }
}).$mount("#app");