import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "home",
        component: Home
    },
    // {
    //   path: "/",
    //   name: "home",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/Home.vue")
    //   // meta: {
    //   // reload: true,
    //   // requiresAuth: true
    //   // }
    // },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue")
            // meta: {
            // reload: true,
            // requiresAuth: true
            // }
    },
    {
        path: "/partners",
        name: "partners",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Partners.vue")
    },
    {
        path: "/partners/:id",
        name: "partners",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/SubPartners.vue"
            )
    },
    {
        path: "/affiliates",
        name: "affiliates",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Affiliates.vue")
    },
    {
        path: "/affiliates/:id",
        name: "affiliates",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/subAffiliates.vue"
            ),
        children: [{
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "login",
            component: () =>
                import (
                    /* webpackChunkName: "viewName" */
                    "../views/childrens/login.vue"
                )
        }]
    },
    {
        path: "/members",
        name: "members",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Members.vue")
    },
    {
        path: "/members/:id",
        name: "members",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/SubMembers.vue"
            )
    },
    {
        path: "/board-members-management",
        name: "boardMembers",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/BoardMembers.vue")
    },
    {
        path: "/board-members-management/etf-staff",
        name: "boardMembers",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/EtfStaff.vue"
            )
    },
    {
        path: "/board-members-management/board-members",
        name: "boardMembers",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/BoardMembers.vue"
            )
    },
    {
        path: "/board-members-management/board-members/:id",
        name: "boardMembers",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/SubBoardMembers.vue"
            )
    },
    {
        path: "/information",
        name: "information",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Information.vue")
            // children: [
            //   {
            //     // UserProfile will be rendered inside User's <router-view>
            //     // when /user/:id/profile is matched
            //     path: "login",
            //     component: () =>
            //       import(
            //         /* webpackChunkName: "viewName" */ "../views/childrens/login.vue"
            //       )
            //   }
            // ]
    },
    {
        path: "/committes",
        name: "committes",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Committes.vue")
    },
    {
        path: "/committes/:id",
        name: "committes",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/subCommittes.vue"
            ),
        children: [{
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "login",
            component: () =>
                import (
                    /* webpackChunkName: "viewName" */
                    "../views/childrens/login.vue"
                )
        }]
    },
    {
        path: "/projects",
        name: "projects",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Projects.vue")
    },
    {
        path: "/projects/:id",
        name: "projects",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/SubProjects.vue"
            )
    },
    {
        path: "/trade-topics",
        name: "tradeTopics",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/TradeTopics.vue")
    },
    {
        path: "/career-development",
        name: "careerDevelopment",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/CareersDevelopment.vue"
            )
    },
    {
        path: "/information-center",
        name: "informationCenter",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/InformationCenter.vue"
            )
    },
    {
        path: "/activites",
        name: "activites",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/404.vue")
    },
    {
        path: "/news-room",
        name: "newsRoom",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/NewsRoom.vue")
    },
    {
        path: "/news-room/:id",
        name: "newsRoom",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "viewName" */
                "../views/internal-pages/SubNewsRoom.vue"
            )
    },
    {
        path: "/doing-business",
        name: "doingBusiness",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/doingBusiness.vue")
    },
    {
        path: "/search",
        name: "doingBusiness",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/search.vue")
    },
    {
        path: "/statistics",
        name: "statistics",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/404.vue")
    },
    {
        path: "/careers",
        name: "careers",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/Careers.vue")
    },
    {
        path: "/contact-us",
        name: "contactUs",
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "viewName" */ "../views/ContactUs.vue")
    },
    {
        path: "404",
        name: "404",
        component: () =>
            import ("../views/notFound.vue")
    },
    {
        path: "*",
        name: "404",
        component: () =>
            import ("../views/notFound.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;