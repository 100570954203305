<template>
  <div id="CalenderComp">
    <vue-event-calendar :events="row">
      <!-- <vue-event-calendar :events="demoEvents"> -->
      <template scope="props">
        <div v-for="(event, index) in props.showEvents" class="event-item" :key="index">
          <!-- {{event}} -->
          <div class="wrapper">
            <h3 class="title">• {{event[$i18n.locale + '_title']}}</h3>
            <p class="time">{{event.date}}</p>
            <p class="desc">{{event[$i18n.locale + '_desc']}}</p>
          </div>
        </div>
        <div v-if=" (row && row.length ? row.length : 0) == 0">
             <h4 class="title">{{ $t("not_event") }}</h4>
          </div>
      </template>
    </vue-event-calendar>
  </div>
</template>
<script>
export default {
  name: "CalenderComp",
  methods: {
    goToday() {
      this.$refs.calendar.goToday();
    }
  },
  components: {
    // Calendar // Registering Component
  },
  data: function() {
    return {
      
      row: []
    };
  },
  mounted() {
    
    this.$http
      .get("event/world")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }
};
</script>
