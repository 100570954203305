<template>
  <section id="footer" class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4 text-center order-md-12">
          <h4>
            <i class="fas fa-envelope-open-text"></i> 
            {{ $t("subscribe") }}
            <!-- {{ $t("title.subscribeBtn") }} -->
          </h4>
          <input
            type="email"
            :placeholder="$t('email')"
            v-model.trim="$v.email.$model"
            :class="{
              'is-invalid': $v.email.$error,
              'is-valid': !$v.email.$invalid
            }"
          />
          <div class="valid-feedback">
            <span>• {{$t('email')}} {{$t("c6")}}</span>
          </div>
          <div class="invalid-feedback">
            <span v-if="!$v.email.required">• {{$t('email')}} {{$t("c2")}}</span>
            <span v-if="!$v.email.inUnique">• {{$t('email')}} {{$t("c7")}}</span>
          </div>
          <button @click="formSubmit">{{$t("subscribeBtn")}}</button>
        </div>

        <div class="col-md-4 order-md-6" >
        <!-- <div class="col-md-4 order-md-6 padding-social" > -->
          <!-- <h3>Quick links</h3> -->
          <!-- <div class="row justify-content-center logos-f" v-show="home =='home'">
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="فنادق"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="شركات"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="منشآت"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="سلع"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="غوص"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="هيئة التنشيط"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="WTTC"/></a>
            </div>
            <div class="col-2-5 text-center">
              <a href="#"><img src="../assets/images/1.png" alt="" title="UNWTO"/></a>
            </div>
          </div> -->
          <!-- {{row}} -->
          <div  :class="[this.$route.name != 'home' ? 'postion-abs social-links text-center' : 'social-links text-center']">
            <a :href="row.facebook" v-if="row.facebook != null" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a :href="row.youtube" v-if="row.youtube != null" target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
            <a :href="row.instagram" v-if="row.instagram != null" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
            <a :href="row.twitter" v-if="row.twitter != null" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <img class="img-fluid logo" src="../assets/images/logo-f.png" alt />
          <p>
            {{
            $t("copy_right")
            }}
          </p>
          <p>
            {{
            $t("powered")
            }}
            <a href="https://promolinks.com">
              <img class="img-fluid" src="../assets/images/promolinks.png" alt />
            </a>
          </p>
        </div>
      </div>
    </div>
    <notifications group="foo" />
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "FooterComp",
  props: {
    msg: String
  },
  data() {
    return {
      email: "",
      output: "",
      stat: "",
      row:[]
    };
  },
  validations: {
    email: {
      required,
      email,
      inUnique(value) {
        if (value === "") return true;

        var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(email_regex.test(value));
          }, 350 + Math.random() * 300);
        });
      }
    }
  },
  methods: {
    formSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$submitstatus = "fail";
      } else {
        e.preventDefault();
        let currentObj = this;
        this.$http
          .post("subscribe?email=" + this.email)
          .then(function(response) {
            currentObj.output = response.data;
            currentObj.output = "You are now subscribed to the newsletter";
            currentObj.stat = "success";
          })
          .catch(function(error) {
            currentObj.stat = "error";
            currentObj.output = error;
            currentObj.output = "This mail is already subscribed OR "+ error;
          });

        this.$notify({
          group: "foo",
          type: this.stat,
          title: "Important message",
          text: this.output
        });
      }
    }
  },
  computed:{
    home(){
      return this.$route.name;
    }
  },  
  mounted() {
    
    this.$http
      .get("settings")
      .then(response => {
        this.row = response.data.data;
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));

    console.clear();
  }
};
</script>
