<template>
  <transition>
    <div v-if="isLoading" class="loading">
      <div class="loader"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PageLoader",
  data() {
    return {
      isLoading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }
};
</script>

<style scoped lang="sass">

.loading
	position: fixed
	top: 0
	left: 0
	height: 100vh
	width: 100vw
	z-index: 9
	background-color: #3c374bd1
	top: 0
	bottom: 0

.loader
	position: fixed
	top: 50%
	left: 50%
	transform: translateX(-50%) translateY(-50%)
	width: 32px
	height: 32px
	&:before,
	&:after
		content: ''
		border-radius: 50%
		position: absolute
		width: 32px
		height: 32px
		background: #ff4262

	&:before
		animation: leftA 1.5s linear infinite

	&:after
		animation: rightA 1.5s linear infinite


@keyframes leftA
	0%, 100%
		left: 100%
		transform: scale(.7)
	25%, 75%
		left: 0
		transform: scale(1)
	50%
		left: 0
		transform: scale(1.5)


@keyframes rightA
	0%, 100%
		right: 100%
		transform: scale(.7)
	25%, 75%
		right: 0
		transform: scale(1)
	50%
		right: 0
		transform: scale(1.5)
</style>
