export default {
    ar: {
        month: {
            "1": "يناير",
            "2": "فبراير",
            "3": "مارس",
            "4": "ابريل",
            "5": "مايو",
            "6": "يونيو",
            "7": "يوليو",
            "8": "أغسطس",
            "9": "سبتمبر",
            "10": "أكتوبر",
            "11": "نوفمبر",
            "12": "ديسمبر"
        },
        title: {
            blogs: "المدونات",
            users: "المستخدمين",
            roles: "الأدوار",
            admins: "المسؤلين",
            messages: "الرسائل",
            subscribers: "المشتركين",
            slider: "سلايدار",
            testimonials: "الشهادات - التوصيات",
            profile: "الصفحه الشخصية",
            settings: "الأعدادات",
            general: "العامة",
            tags: "الكلمات الدلالية",
            home: "الرئيسية",
            about_us: "من نحن",
            contact_us: "تواصل معنا",
            search: "البحث",
            search2: "بحث",
            search3: "ابحث فى 'القوانين والتشريعات السياحية'",
            search4: "ابحث فى ' الأخبار '",
            customer_support: "دعم العملاء",
            get_touch: "ابقى على تواصل",
            services: "الخدمات",
            products: "المنتجات",
            careers: "الوظائف",
            categories: "الأقسام",
            clients: "العملاء",
            login: "تسجيل الدخول",
            register: "الأشتراك",
            username: "إسم المستخدم",
            password: "كلمة السر",
            forgot_password: "تذكر كلمة المرور",
            reset_passeord: "أستعادة كلمة المرور",
            coming_soon: "أنتظرونا قريبا",
            coming_soon_desc: "يخضع موقعنا حاليًا للصيانة المجدولة. يجب أن نعود قريباً شكرا لك على وقتك.",
            why_us: "لماذا نحن",
            our_services: "خدماتنا",
            videos: "أشرطة فيديو",
            news: "الأخبار",
            articles: "المقالات",
            advices: "النصائح",
            properties: "الخصائص",
            specifications: "المواصفات",
            features_benefits: "الميزات والفوائد",

            partners: "الغرف السياحية",
            affiliates: "الشركاء",
            information: "بيانات ومعلومات سياحية",
            members: "الأعضاء",
            members_g: "أعضاء الجمعية العمومية",
            board_members: " أعضاء مجلس الإدارة",
            board_members_m: "أعضاء المجلس",
            committes: "اللجان",
            projects: "المشاريع ومراكز التدريب والأنشطة",
            trade_topics: "الترويج السياحى",
            career_development: "موظفي الإتحاد",
            information_center: "مركز المعلومات",
            activites: "فعاليات الإتحاد",
            news_room: "غرفة الأخبار",
            doing_business: "القوانين والتشريعات السياحية",
            statistics: "الإحصائيات",
            etf_staff: "موظفي الإتحاد",

            favorite: "المفضل"
        },
        pagination: {
            previous: "السابق",
            next: "التالى"
        },
        read_more: "أقرء المزيد",
        more: "المزيد",
        submit: "إرسال",
        lang: "ar",
        more_projects: "مشاريع أخرى",
        project_photos: "صور المشروع",
        more_news: "أخبار أخرى",
        welcome: "مرحبا",
        powered: "مدعوم بواسطة",
        copy_right: "جميع الحقوق محفوظة للاتحاد المصرى للغرف السياحية",
        subscribe: "إشترك فى نشرتنا الإخبارية",
        subscribeBtn: "إشترك الآن",
        contact_subscribe: "اشترك: تابع أحدث أخبار السياحة عن طريق تلقي نشرتنا الاعلامية المرسلة بالبريد الإلكترونى التسجيل مجانا وبياناتك ستسجل لدينا في قاعدة البيانات.",
        first_name: "الأسم الأول:",
        last_name: "الأسم الثانى:",
        name: "الأسم:",
        email: "البريد الإلكترونى :",
        working_hour: "ساعات العمل",
        fax: "الفاكس :",
        telephone: "التيلفون الأرضى :",
        mobile: "الهاتف :",
        department: "القسم:",
        job: "الوظيفة :",
        company: "الشركة :",
        country: "الدولة :",
        location: "العنوان :",
        careers_type: "نوع الوظائف",
        cv: "السيرة الذاتية",
        subject: "الموضوع",
        important_news: "هذا الخبر هام جدا وعاجل.",
        not_found: "لا يوجد بيانات",
        not_event: "لا يوجد أحداث",
        success: "تم ارسال طلبك بنجاح",
        error: "لم يتم إرسال هذا الطلب بنجاح",

        c1: "صحيح .",
        c2: "مطلوب.",
        c3: "على الاقل يجب أن يكون",
        c4: "على الأكثر يجب أن يكون",
        c5: "حروف",
        c6: "صالح.",
        c7: "خاطئ",
        c8: "رقم",
        c9: "يجب أن يكون بصيغة PDF",
        search_results: "نتائج البحث",
    },
    en: {
        month: {
            "1": "Jan",
            "2": "Feb",
            "3": "March",
            "4": "April",
            "5": "May",
            "6": "June",
            "7": "July",
            "8": "Aug",
            "9": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec"
        },
        title: {
            blogs: "Blogs",
            users: "Users",
            admins: "Admins",
            roles: "Roles",
            messages: "Messages",
            subscribers: "Subscribers",
            slider: "Slider",
            testimonials: "Testimonials",
            profile: "Profile",
            settings: "Settings",
            general: "General",
            tags: "Tags",
            home: "Home",
            about_us: "About Us",
            contact_us: "Contact Us",
            search: "Search",
            search2: "Search",
            search3: "Search in 'Tourism rules and regulations'",
            search4: "Search in 'News'",
            get_touch: "Get In Touch",
            services: "Services",
            products: "Products",
            careers: "Vacancies",
            categories: "Categories",
            clients: "Clients",
            login: "Login",
            register: "Register",
            username: "username",
            password: "Password",
            forgot_password: "Forgot Password",
            reset_passeord: "Reset Passeord",
            coming_soon: "Comming Soon",
            coming_soon_desc: "Our website is currently undergoing scheduled maintenance. We Should be back shortly. Thank you for your patience.",
            videos: "Videos",
            news: "News",
            articles: "Articles",
            advices: "Advices",
            properties: "Properties",
            specifications: "Specifications",
            features_benefits: "Features and Benefits",

            partners: "Tourism Chambers",
            affiliates: "Partners",
            information: "Tourism Information",
            members: "Members",
            members_g: "General assembly members",
            board_members: "Board Members",
            board_members_m: "Board Members",
            committes: "Committes",
            projects: "Projects, Training Centers and Activities",
            trade_topics: "Tourism Promotion",
            favorite: "Favorite",
            career_development: "The Team",
            information_center: "Information Center",
            activites: "ETF Events",
            news_room: "News Room",
            doing_business: "Laws and Decrees",
            statistics: "Statistics",
            etf_staff: "The Team"
        },
        pagination: {
            previous: "Previous",
            next: "Next"
        },
        read_more: "Read More",
        more: "More",
        submit: "Submit",
        lang: "en",
        more_projects: "More Projects",
        project_photos: "Project Photos",
        more_news: "More News",
        welcome: "Welcome",
        powered: "Powered By",
        copy_right: "All rights reserved © " + new Date().getFullYear() + " Egyptian Tourism Federation.",
        subscribeBtn: "Subscribe",
        subscribe: "Subscribe To Our Newsletter",
        contact_subscribe: "Subscribe: Follow the latest tourism news by receiving our e-mail newsletter. Registration is free and your data will be recorded in the database.",
        working_hour: "Working Hour :",
        fax: "Fax :",
        telephone: "Telephone :",
        email: "Email :",
        first_name: "First Name :",
        last_name: "Last Name :",
        name: "Name :",
        mobile: "Mobile :",
        department: "Department :",
        job: "Job",
        company: "Company",
        country: "Country",
        location: "Location :",
        careers_type: "Careers Type",
        cv: "CV",
        subject: "Subject",
        important_news: "This news is very important and urgent.",
        not_found: "Not Data Found.",
        not_event: "There are no events.",
        success: "Your request has been sent successfully",
        error: "your request has not been sent successfully",



        c1: "is correct.",
        c2: "is required.",
        c3: "must have at least",
        c4: "must have at most",
        c5: "Letters",
        c6: "is valid",
        c7: "is Wrong",
        c8: "Number",
        c9: "Must be PDF",
        search_results: "Search Results",
    }
};