<template>
<div>
  <!-- <div v-if="row3 == 0" id="app" :class="[$i18n.locale == 'ar' ? 'ar' : '']"> -->
  <div id="app" :class="[$i18n.locale == 'ar' ? 'ar' : '']">
    <!-- <div class="news-alert">
      <div
        class="alert alert-success fade show alert-dismissible"
        role="alert"
        v-for="(r, i) in row1"
        :key="r.id + i"
      >
        <h4 class="alert-heading">{{ r[$i18n.locale + "_title"] }}</h4>
        <p>
          <div v-html="r[$i18n.locale+'_desc']"></div>
          {{ getPragraphLimit(r[$i18n.locale + "_desc"], 800) }}
          <router-link :to="'/news-room/' + r.en_slug" tag="a">{{
            $t("read_more")
          }}</router-link>
        </p>
        <hr />
        <p class="mb-0">{{$t("important_news")}}</p>
        <button type="button" class="close" v-on:click="fadeOut()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="alert alert-danger fade show alert-dismissible"
        role="alert"
        v-for="(r, i) in row2"
        :key="r.id + i+2"
      >
        <h4 class="alert-heading">{{ r[$i18n.locale + "_title"] }}</h4>
        <p>
          {{ getPragraphLimit(r[$i18n.locale + "_desc"], 800) }}
          <router-link :to="'/news-room/' + r.en_slug" tag="a">{{
            $t("read_more")
          }}</router-link>
        </p>
        <hr />
        <p class="mb-0">{{$t("important_news")}}</p>
        <button type="button" class="close" v-on:click="fadeOut()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div> -->
    <div class="calender-p">
      <span class="calender-close">
        <img src="./assets/images/arrow.png" alt />
      </span>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class data-toggle="tab" href="#world">
            <span class="world">World</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="active" data-toggle="tab" href="#etf">
            <span class="etf">ETF</span>
          </a>
        </li>
      </ul>
      <div class="calender">
        <div class="tab-content">
          <div id="etf" class="active tab-pane">
            <etfCalenderComp />
          </div>
          <div id="world" class="tab-pane">
            <worldCalenderComp />
          </div>
        </div>
      </div>
    </div>
    <PageLoader />
    <HeaderComp />
    <router-view />
    <FooterComp />
  </div>
  <div v-if="row3 == 1">Not available access!</div>
</div>
</template>

<script>
// @ is an alias to /src
import PageLoader from "@/components/PageLoader.vue";
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import etfCalenderComp from "@/components/etfCalenderComp.vue";
import worldCalenderComp from "@/components/worldCalenderComp.vue";
import $ from "jquery";

export default {
  name: "home",
  components: {
    PageLoader,
    HeaderComp,
    FooterComp,
    etfCalenderComp,
    worldCalenderComp,
  },
  data: function () {
    return {
      
      row1: [],
      row2: [],
      row3: null,
    };
  },
  methods: {
    getDanger() {
      // 
      this.$http
        .get("danger")
        .then((response) => {
          this.row1 = response.data.data;
        })
        .catch((error) => console.log(error));
    },
    getSuccess() {
      // 
      this.$http
        .get("feature")
        .then((response) => {
          this.row2 = response.data.data;
        })
        .catch((error) => console.log(error));
    },    
    isEncrypt() {
      // 
      this.$http
        .get("getEncrypt")
        .then((response) => {
          this.row3 = response.data;
        })
        .catch((error) => console.log(error));
    },
    fadeOut(){
        $(".news-alert").fadeOut();
    }
  },
  mounted() {
    this.getDanger();
    this.getSuccess();
    // this.isEncrypt();
    console.clear();
  },
};
</script>
