import $ from "jquery";

$(document).ready(function() {
    $(".caalender-icon").click(function() {
        $(".calender-p").animate({
                width: "show"
            },
            300
        );

        $(".caalender-icon").animate({
                width: "hide"
            },
            300
        );
    });

    $(".calender-close ").click(function() {
        $(".calender-p").animate({
                width: "hide"
            },
            300
        );

        $(".caalender-icon").animate({
                width: "show"
            },
            300
        );
    });

    $(".img").click(function() {
        $(this).toggleClass("rotate-img");

        $(".internal-nav").toggleClass("left-0");
    });


    console.clear();
});