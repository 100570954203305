<template>
  <section id="header">
    <!-- <div class="status-internet" v-if="window.navigator.onLine == 'off'">
      no internt
    </div> -->
    <div v-if="isOnline"></div>
    <div v-else class="offline">
      <strong>You are offline now!</strong> Please make sure you are connected to the internet.
    </div>
    <div class="row">
      <div class="col-6 text-left">
        <div class="logo container">
          <router-link to="/">
            <img class="img-fluid" src="../assets/images/logo-h.png" alt />
            <img id="logo" width="130px" class="img-fluid d-none" src="../assets/images/logo-f.png" alt />
          </router-link>
        </div>
      </div>
      <div class="col-6 text-right">
        <div class="row">
          <div class="col-6 col-md-9 col-lg-10">
            <CalenderIcon />
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div class="lang-srch">
              <button data-toggle="modal" data-target="#myModal">
                <i class="fas fa-search"></i>
              </button>
              <button
                class="chng-lang"
                v-for="(lang, i) in langs"
                :key="i"
                v-on:click="change_locale(lang)"
                v-show="$i18n.locale != lang"
              >
                <!-- <i class="fas fa-globe"></i> -->
                <i v-if="$i18n.locale == 'ar'">English</i>
                <i v-else>العربية</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sticky" class="nav-home">
      <div class="small-nav nav-display2">
        <div class="d-flex -container">
          <li>
            <router-link to="/" tag="a" exact>{{ $t("title.home") }}</router-link>
          </li>
          <li :class="[this.$route.name === 'boardMembers' ? 'active' : '']">
            <router-link to="/board-members-management/board-members/Board_Members" tag="a">
              {{ $t("title.board_members_m") }}
            </router-link>
          </li>
          <!-- <li :class="[this.$route.name === 'members' ? 'active' : '']">
            <router-link to="/members" tag="a">
              {{ $t("title.members_g") }}
            </router-link>
          </li> -->
          <li :class="[this.$route.name === 'careerDevelopment' ? 'active' : '']">
            <router-link to="/board-members-management/etf-staff" tag="a">
              {{ $t("title.career_development") }}
            </router-link>
          </li>
          <li :class="[this.$route.name === 'partners' ? 'active' : '']">
            <router-link to="/partners" tag="a">
              {{ $t("title.partners") }}
            </router-link>
          </li>
          <!-- <li :class="[this.$route.name === 'affiliates' ? 'active' : '']">
            <router-link to="/affiliates" tag="a">
              {{ $t("title.affiliates") }}
            </router-link>
          </li> -->
          <!-- <li :class="[this.$route.name === 'committes' ? 'active' : '']">
            <router-link to="/committes" tag="a">
              {{ $t("title.committes") }}
            </router-link>
          </li> -->
          <li :class="[this.$route.name === 'projects' ? 'active' : '']">
            <router-link to="/projects" tag="a">
              {{ $t("title.projects") }}
            </router-link>
          </li>
        </div>
      </div>
      <div class="navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">

          <router-link to="/" class="navbar-brand">
            <img width="100px" class="img-fluid" src="../assets/images/logo-f.png" alt />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item nav-display" :class="[this.$route.name === '' ? 'active' : '']">
                <router-link to="/" tag="a" class="nav-link">{{
                  $t("title.home")
                }}</router-link>
              </li>
              <li class="nav-item nav-display" :class="[this.$route.name === 'boardMembers' ? 'active' : '']">
                <router-link
                  to="/board-members-management/board-members"
                  class="nav-link"
                  tag="a"
                >
                  {{ $t("title.board_members_m") }}
                </router-link>
              </li>
              <li class="nav-item nav-display" :class="[this.$route.name === 'careerDevelopment' ? 'active' : '']">
                <router-link to="/board-members-management/etf-staff" class="nav-link" tag="a">
                  {{ $t("title.career_development") }}
                </router-link>
              </li>
              <li class="nav-item nav-display" :class="[this.$route.name === 'partners' ? 'active' : '']">
                <router-link class="nav-link" to="/partners" tag="a">
                  {{ $t("title.partners") }}
                </router-link>
              </li>
              <!-- <li class="nav-item nav-display" :class="[this.$route.name === 'affiliates' ? 'active' : '']">
                <router-link class="nav-link" to="/affiliates" tag="a">
                  {{ $t("title.affiliates") }}
                </router-link>
              </li> -->
              <!-- <li class="nav-item nav-display" :class="[this.$route.name === 'members' ? 'active' : '']">
                <router-link class="nav-link" to="/members" tag="a">
                  {{ $t("title.members_g") }}
                </router-link>
              </li> -->
              <!-- <li class="nav-item nav-display" :class="[this.$route.name === 'committes' ? 'active' : '']">
                <router-link class="nav-link" to="/committes" tag="a">
                  {{ $t("title.committes") }}
                </router-link>
              </li> -->
              <li class="nav-item nav-display" :class="[this.$route.name === 'projects' ? 'active' : '']">
                <router-link class="nav-link" to="/projects" tag="a">
                  {{ $t("title.projects") }}
                </router-link>
              </li>
              <!-- <li class="nav-item" :class="[this.$route.name === 'information' ? 'active' : '']">
                <router-link class="nav-link" to="/information" tag="a">
                  {{ $t("title.information") }}
                </router-link>
              </li> -->
              <li class="nav-item" :class="[this.$route.name === 'careers' ? 'active' : '']">
                <router-link to="/careers" class="nav-link" tag="a">
                  {{ $t("title.careers") }}
                </router-link>
              </li>
              <li class="nav-item" :class="[this.$route.name === 'tradeTopics' ? 'active' : '']">
                <router-link class="nav-link" to="/trade-topics" tag="a">
                  {{ $t("title.trade_topics") }}
                </router-link>
              </li>
              <li class="nav-item" :class="[this.$route.name === 'newsRoom' ? 'active' : '']">
                <router-link to="/news-room" class="nav-link" tag="a">
                  {{ $t("title.news_room") }}
                </router-link>
              </li>
              <!-- <li class="nav-item" :class="[this.$route.name === 'informationCenter' ? 'active' : '']">
                <router-link to="/information-center" class="nav-link" tag="a">
                  {{ $t("title.information_center") }}
                </router-link>
              </li> -->
              <li class="nav-item" :class="[this.$route.name === 'doingBusiness' ? 'active' : '']">
                <router-link to="/doing-business" class="nav-link" tag="a">
                  {{ $t("title.doing_business") }}
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link to="/activites" class="nav-link" tag="a">
                  {{ $t("title.activites") }}
                </router-link>
              </li> -->
              <!-- <li class="nav-item">
                <router-link to="/statistics" class="nav-link" tag="a">
                  {{ $t("title.statistics") }}
                </router-link>
              </li> -->
              <li class="nav-item" :class="[this.$route.name === 'contactUs' ? 'active' : '']">
                <router-link to="/contact-us" class="nav-link" tag="a">
                  {{ $t("title.contact_us") }}
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div
      class="modal fade text-center"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1>{{ $t("title.search") }}</h1>
            <form class="navbar-form" role="search">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('title.search3')"
                  v-model.trim="$v.search.$model"
                  :class="{
                    'is-invalid top-cat-field': $v.search.$error,
                    'is-valid top-cat-field': !$v.search.$invalid,
                    'top-cat-field': 'top-cat-field',
                  }"
                />
                <div class="valid-feedback no-shadow">
                  <span>• {{$t('title.search')}} {{$t("c6")}}</span>
                </div>
                <div class="invalid-feedback">
                  <span v-if="!$v.search.required">• {{$t('title.search')}} {{$t("c2")}}</span>
                  <span v-if="!$v.search.minLength">
                    • {{$t('title.search')}} {{$t("c3")}}
                    {{ $v.search.$params.minLength.min }} {{$t("c5")}}
                  </span>
                </div>
              </div>
              <router-link :to="'/search?input=' + this.search">
                <button type="submit" class="btn btn-default">
                  {{ $t("title.search2") }}
                </button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import CalenderIcon from "@/components/CalenderIcon.vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "HeaderComp",
  data() {
    return { langs: ["ar", "en"], search: "",isOnLine:navigator.onLine };
  },
  validations: {
    search: {
      required,
      minLength: minLength(3),
    },
  },
  components: {
    CalenderIcon,
  },
  methods: {
    change_locale(lang) {
      this.$i18n.locale = lang;
      this.$cookies.set('lang', lang);
    },
  },
  computed: {
    home() {
      return this.$route.name;
    },
  },
  mounted: function () {
    $(document).ready(function () {
      $(".caalender-icon").click(function () {
        $(".calender-p").animate(
          {
            width: "show",
          },
          300
        );

        $(".caalender-icon").animate(
          {
            width: "hide",
          },
          300
        );
      });

      $(".calender-close ").click(function () {
        $(".calender-p").animate(
          {
            width: "hide",
          },
          300
        );

        $(".caalender-icon").animate(
          {
            width: "show",
          },
          300
        );
      });

      $(".nav-item").click(function() {
        $("#navbarNav").toggleClass("show");
      });
    });
     let sticky = $('#sticky'),
    stickyTop = sticky.offset().top,
    scrolled = false,
    $window = $(window);

    /* Bind the scroll Event */
    $window.on('scroll', function() {
        scrolled = true;
    });

    let timeout = setInterval(function() {
        /* If the page was scrolled, handle the scroll */
        if (scrolled) {
            scrolled = false;
            if ($window.scrollTop() >= stickyTop) {
                sticky.addClass('fixed');
                 $('#logo').addClass('logo-fixed');
                // console.log("sticky");
            }
            else {
            sticky.removeClass('fixed');
             $('#logo').removeClass('logo-fixed');
            }
        }
    }, 200);


    console.log(timeout);
    console.clear();
  },
};
</script>
