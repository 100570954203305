<template>
  <div id="calender">
    <div class="caalender-icon">
      <div class="item-img">
        <span class="date1">{{ today.getDate() }}</span>
        <span class="date2">{{ $t("month." + (today.getMonth() + 1)) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalenderIcon",
  data() {
    const today = new Date();
    return {
      today: today
    };
  }
};
</script>

<style scoped>
.item-img {

    transform: scale(.8);
  /* background-color: #333; */
  color: #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(47%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(237, 237, 237, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(237, 237, 237, 1) 100%
  );
  filter: gradient(
    startColorstr= "#ffffff",
    endColorstr= "#ededed",
    GradientType=0
  );
  text-align: center;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.date2 {
  width: 100%;
  display: block;
  background: rgb(177, 32, 40);
  background: -moz-linear-gradient(
    top,
    rgb(177, 32, 40) 0%,
    rgba(177, 32, 40, 0.78) 45%,
    rgb(177, 32, 40) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(181, 50, 27, 1)),
    color-stop(45%, rgba(177, 32, 40, 0.78)),
    color-stop(100%, rgb(177, 32, 40))
  );
  background: -webkit-linear-gradient(
    top,
    rgb(177, 32, 40) 0%,
    rgba(177, 32, 40, 0.78) 45%,
    rgb(177, 32, 40) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(181, 50, 27, 1) 0%,
    rgba(177, 32, 40, 0.78) 45%,
    rgb(177, 32, 40) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(177, 32, 40) 0%,
    rgba(177, 32, 40, 0.78) 45%,
    rgb(177, 32, 40) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(177, 32, 40) 0%,
    rgba(177, 32, 40, 0.78) 45%,
    rgb(177, 32, 40) 100%
  );
  filter: gradient(
    startColorstr= "#b5321b",
    endColorstr= "#b5321b",
    GradientType=0
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.date1 {
  color: #333;
  font-family: "Exo 2", sans-serif;
  display: block;
  font-size: 50px;
  width: 100%;
}
</style>
