<template>
  <section id="home-page" class="content-height">
    <PageLoader />
    <!-- <HeaderComp /> -->
    <div class="hr"></div>
    <div id="banner" class="banner">
      <!-- <img src="../assets/images/banner.png" alt /> -->
      <!-- <div class="swiper-container swiper-banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(r, i) in row2" :key="r.en_title+i">
            <img
              class="img-fluid"
              :src="
              'http://etf-admin.ispdemos.com/storage/app/public/' + r.img"
              :alt="row[$i18n.locale+'_title']"
            />
          </div>
        </div>
        <img src="../assets/images/banner-overlay.png" alt class="over-lay" />
        <div class="swiper-pagination"></div>
      </div> -->
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div  :class="[i == 0 ? 'active' : ''] + ' carousel-item'"  v-for="(r, i) in row2.data" :key="r.en_title+i" >
            <div class="slide-item-c" :style="{ backgroundImage: ` url(http://etf-admin.ispdemos.com/storage/app/public/${r.img})` }"></div>
          </div>
          <div v-if=" (row2.data && row2.data.length ? row2.data.length : 0) == 0">
            <div id="loading_c"></div>
            <h2 id="not_found" style="color:black;">{{ $t("not_found") }}</h2>
          </div>
        </div>
         <a class="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleSlidesOnly" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        <img src="../assets/images/banner-overlay.png" alt class="over-lay" />
      </div>
    </div>

    <div id="about-us" class="sec-space content-height">
      <div class="container">
        <div class="titleS no-m-t">
          <img src="../assets/images/tittle.png" alt />
          <span> {{ row[$i18n.locale+'_title'] }}</span>
        </div>
        <div class="row content">
          <div class="col-sm-12">
            <p class="ml-5" v-html="getPragraphLimit(row[$i18n.locale + '_desc'],2000)"></p>

            <span class="read-more">
              <router-link to="/about" tag="a">{{ $t("read_more") }}</router-link>
            </span>
          </div>
          <div class="col-sm-12">
            <!-- <img
              class="img-fluid"
              :src="
              'http://etf-admin.ispdemos.com/storage/app/public/' + row.img"
              :alt="row[$i18n.locale+'_title']"
              :title="row[$i18n.locale+'_title']"
            /> -->
            <div class="map" style="height:100%">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.8432073703816!2d31.219610684930622!3d30.041355781883503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840d5850c2177%3A0xe0452d63fffb66f9!2z2KfZhNin2KrYrdin2K8g2KfZhNmF2LXYsdmJINmE2YTYutix2YEg2KfZhNiz2YrYp9it2YrYqQ!5e0!3m2!1sar!2seg!4v1584619868023!5m2!1sar!2seg"
                width="100%"
                height="400"
                frameborder="0"
                style="border:0;"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp from "@/components/HeaderComp.vue";
export default {
  components: {
    PageLoader,
    // HeaderComp
  },
  data() {
    return {
      row: [],
      row2: []
    };
  },
  methods: {
    getpages() {
      this.$http
        .get("pagesBy/4")
        .then(response => {
          this.row = response.data.data;
        })
        .catch(error => console.log(error));
    },
    getslider() {
      this.$http
        .get("slider")
        .then(response => {
          this.row2 = response.data;
        })
        .catch(error => console.log(error));
    }
  },
  mounted() {
    this.loading(7000);
    this.getpages();
    this.getslider();
  }
};
</script>
